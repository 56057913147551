jQuery(function() {
  $('.overlay .tabs a').on('click', function () {
    var $link = $(this);
    var contentId = $link.attr('href');

    if ($link.hasClass('disabled')) return true;
    if ($link.hasClass('active')) return false;

    $link.parents('.overlay')
      .find('.tabs-content.active, .tabs .active').removeClass('active');
    $link.addClass('active');
    $(contentId).addClass('active');

    return false;
  });
});

(function() {

  var preventMultiSubmit = function(e) {
    if (e.target.action.match(/eiiforms/g).length) {
      // Disable button after submit to prevent multiple submissions
      e.preventDefault();
      var form = e.target;
      var submitBtn = form.querySelector('[type="submit"]');
      submitBtn.disabled = true;

      var loadingSpinnerSymbol = document.querySelector('symbol#loading-spinner');

      if (!loadingSpinnerSymbol) {
        console.log('Missing SVG symbol for loading spinner')
        form.submit();
        return;
      }

      var loadingSpinner = '<svg class="loading-spinner">'+
        '<use xlink:href="#loading-spinner" />'+
      '</svg>';

      var innerSpan = submitBtn.querySelector('span');

      if (innerSpan) {
        innerSpan.innerHTML = loadingSpinner;
      } else {
        submitBtn.innerHTML = loadingSpinner;
      }

      form.submit();
    }
  };

  var init = function() {
    document.addEventListener('submit', preventMultiSubmit);
  }

  // var f = function(event) {
  //   var buttons = document.querySelectorAll('form *[type="submit"]');
  //   for (var i = 0; i < buttons.length; i++) {
  //     var button = buttons[i];
  //
  //     var form = button.form;
  //     if (form.action.indexOf('eiiforms.com') == -1) { continue; }
  //
  //     var av = button.getAttribute('data-click');
  //
  //     // Data click is blank or doesn't exist, so bail out early, this functionality
  //     // won't be enabled.
  //     if (av === null || av === "") return true;
  //
  //     var submitHandler = (function(element, action) {
  //       return function(formEvent) {
  //         if (!formEvent.defaultPrevented) {
  //           if (action == "disable") {
  //             element.setAttribute('disabled', 'disabled');
  //             setTimeout(function() {
  //               element.removeAttribute('disabled');
  //             }, 2000);
  //           }
  //         };
  //       };
  //     })(button, av);
  //
  //     form.addEventListener('submit', submitHandler);
  //
  //   }
  //
  // };

  if (document.readyState !== 'loading') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }

})();

var AddParamsToForms = function () {
  var parseQueryString = function(queryString) {
    if (queryString === "" || queryString == "?") {
      return [];
    }

    if (queryString[0] == "?") {
      queryString = queryString.substring(1);
    }

    var vars = queryString.split('&');
    var results = [];
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');

      var name = decodeURIComponent(pair[0]);
      var value = "";
      if (typeof pair[1] == "undefined") {
        value = null;
      } else {
        value = decodeURIComponent(pair[1]);
      }
      results.push([name, value]);
    }
    return results;
  };

  qsParams = parseQueryString(window.location.search);

  var _forms = document.getElementsByTagName('form');

  for (var i = 0; i < _forms.length; i++) {
    if (_forms[i].action.indexOf('eiiforms.com') == -1) { continue; }
    var f = _forms[i];

    for (var ic = 0; ic < qsParams.length; ic++) {
      var pair = qsParams[ic];
      if (pair[0] === "" || pair[1] === null) { continue; }
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', '_qs_'+pair[0]);
      input.setAttribute('value', pair[1]);
      f.appendChild(input);
    }
  }
};

if (document.readyState !== 'loading') {
  AddParamsToForms();
} else {
  document.addEventListener("DOMContentLoaded", function(event) {
    AddParamsToForms();
  });
}

SlickSlider = function() {
  this.init = function() {
    $('.photogallery .image-sets').slick({
      slide: '.image-set',
      dots: true,
      dotsClass: 'dot',
      prevArrow: $('#previous-arrow'),
      nextArrow: $('#next-arrow'),
      infinite: false,
      customPaging: dot
    });
  };

  var dot = function() {
    return '<a class="dot-link" type="button" data-role="none" ' +
           'role="button" aria-required="false" tabindex="0"></a>';
  };

  this.init();
}

$(function () {
  new SlickSlider;
});

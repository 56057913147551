var fixedMenuClass = 'fixed-menu';
var scrolledMenuClass = 'scrolled-down';

var scrolledAwayFromTop = function (scrollTop) {
  return scrollTop > 0;
};

var activateFixedHeader = function ($header) {
  if (overlayOpen()) return;

  var $window = $(this);
  var scrollTop = $window.scrollTop();

  if (scrolledAwayFromTop(scrollTop)) {
    $header.addClass(scrolledMenuClass);
  } else {
    $header.removeClass(scrolledMenuClass);
  }
};

var resizeMenu = function ($link, origLinkHeight) {
  if (overlayOpen()) return;

  var $window = $(this);
  var scrollTop = $window.scrollTop();

  var newLinkHeight = origLinkHeight - scrollTop;

  if (newLinkHeight > 1) {
    $link.css('height', newLinkHeight + 'px');
  } else {
    $link.css('height', '1px');
  }
};

$(function () {
  var $header = $('#page-header');
  var $body   = $('body');
  var $link   = $header.find('.menu-item a');

  var headerHeight   = $header.height();
  var borderHeight   = 9;
  var origLinkHeight = $link.height();

  headerHeight = headerHeight + borderHeight;

  $header.addClass(fixedMenuClass);
  // push down body after fixing header so image is at the same spot
  $body.css('margin-top', headerHeight + 'px');

  activateFixedHeader($header);
  resizeMenu($link, origLinkHeight);
  $(window).on('scroll touchmove', function () {
    activateFixedHeader($header);
    resizeMenu($link, origLinkHeight);
  });
});

setupVideoMedia = function ($video, videoType) {
  var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  var me_mode = 'auto';
  if (is_firefox) {
    me_mode = 'auto_plugin';
  }
  new MediaElementPlayer($video, {
    type: videoType,
    mode: me_mode,
    startVolume: 0.8,
    videoWidth: '100%',
    videoHeight: '100%',
    enableAutosize: true,
    AndroidUseNativeControls: true,
    iPhoneUseNativeControls: true,
    iPadUseNativeControls: true,
    pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
    plugins: ['flash'],
    flashName: 'flashmediaelement-cdn.swf'
  });
};

$(function () {
  $('.media.fragment, .content.fragment').not('.media.fragment.looping').each(function () {
    var $video    = $(this).find('video');
    var videoType = $video.data('type');

    if ($video.length) {
      setupVideoMedia($video, videoType);
    }

    $(this).fitVids();
  });
});

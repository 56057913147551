jQuery(function($) {

  var attribute = 'google-analytics';
  var e = $('body[data-'+attribute+']:first');

  if (e.length == 0) {
    var attribute = 'google_analytics';
    var e = $('body[data-'+attribute+']:first');
  }

  e.each(function() {
    var $bd = $(this);
    var data = $bd.data(attribute);

    var theme_name = $bd.data("theme-name");
    var theme_version = $bd.data("theme-version");
    var theme_style = $bd.data("theme-style");
    var cl_type = $bd.data("ein-cl-type");
    var page_template = $bd.data("page-template-name");
    var content_syndicated = "original";
    if (page_template == "syndicated") {
      content_syndicated = "syndicated";
    }
    var content_type = $bd.data("content-type");

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.eiiwebservices.com/google/analytics.js','ga');



    for (var i = 0; i < data.ids.length; i++) {
      ga('create', data.ids[i], 'auto', {'name': 'tracker'+i});
    }
    ga('create', data.lucid_id, 'auto', {'name': 'lucid', 'legacyCookieDomain': document.domain});

    if (theme_name) {
      ga('lucid.set', 'dimension1', theme_name);
    }

    if (theme_version) {
      ga('lucid.set', 'dimension2', theme_version);
    }

    if (theme_style) {
      ga('lucid.set', 'dimension3', theme_style);
    }

    ga('lucid.set', 'dimension4', document.domain);

    if (cl_type) {
      ga('lucid.set', 'dimension5', cl_type);
    }

    if (page_template) {
      for (var i = 0; i < data.ids.length; i++) {
        ga('tracker'+i+'.set', 'dimension6', content_syndicated);
      }
      ga('lucid.set', 'dimension6', content_syndicated);
    }

    if (content_type) {
      ga('lucid.set', 'dimension7', content_type);
    }

    for (var i = 0; i < data.ids.length; i++) {
      ga('tracker'+i+'.send', 'pageview');
    }
    ga('lucid.send', 'pageview');

  });


  $('*[data-ga-event]').click(function(e) {
    var o = $(this);
    var v = o.attr('data-ga-event');
    var parts = v.split(/\s*,\s*/);
    if (parts.length == 2 && typeof(ga) != "undefined") {
      ga('send', 'event', parts[0], 'click', parts[1]);
      ga('lucid.send', 'event', parts[0], 'click', parts[1]);
    }
  });
});

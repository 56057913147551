var overlayOpen = function () {
  return $('.overlay').hasClass('active');
};

function VideoOverlay(id) {
  this.id = id;
  this.video = null;

  this.init = function() {
    var elem = document.getElementById(this.id);
    if (!elem) return;

    var videoType = 'video/mp4';
    var features = ['playpause', 'current', 'progress', 'duration', 'tracks',
                    'volume', 'fullscreen'];

    this.video = new MediaElementPlayer(elem, {
      type: videoType,
      features: features,
      AndroidUseNativeControls: true,
      iPhoneUseNativeControls: true,
      iPadUseNativeControls: true,
      success: function (mediaElement) {
        mediaElement.addEventListener('ended', function(e) {
          var cta = document.querySelector('.after-play-wrapper');
          if (cta) cta.classList.add('active');
        });
        mediaElement.addEventListener('webkitendfullscreen', function() {
          window.history.back();
        });
      }
    });

    return this.video;
  }

  this.play = function() {
    if (!this.video) return;

    return this.video.play();
  }

  this.pause = function() {
    if (!this.video) return;

    return this.video.pause();
  }

  this.init();
}

var loadDelayedImages = function ($elem) {
  $elem.find('img.delayed').each(function () {
    var $image = $(this);
    var src    = $image.data('src');

    $image.attr('src', src);
    $image.removeClass('delayed');
  });
};

jQuery(function() {
  var $videoOverlay = new VideoOverlay('video-overlay-player');

  var deactivateOverlay = function () {
    $('.overlay').removeClass('active');
    $.scrollLock();
    if ($videoOverlay) {
      $videoOverlay.pause();
    }
  };

  var addPushState = function () {
    var scrollTop = $('body').scrollTop();

    history.replaceState({scrollTop: scrollTop}, null, null);
    history.pushState({overlay: 'open'}, null, null);
  };

  var initPageOverlayCloser = function () {
    window.onpopstate = function (event) {
      if (!overlayOpen()) {
        return;
      }

      var scrollTop = event.state.scrollTop;

      deactivateOverlay();
      if (scrollTop) {
        $('body').scrollTop(scrollTop);
      }
    };

    $(document).on('keyup', function (e) {
      if (!overlayOpen() || e.keyCode !== 27) return;
      // clicked escape

      deactivateOverlay();
    });
  };

  var activateOverlay = function ($elem) {
    $elem.toggleClass('active');
    addPushState();
    $.scrollLock();
    initPageOverlayCloser();
  };

  $('.overlay .close').on('click', function () {
    window.history.back();
  });

  $('.overlay .close-area').on('click', function () {
    window.history.back();
  });

  $('.phone-overlay-link').on('click', function () {
    activateOverlay($('#phone-overlay'));
  });

  $('.locations-overlay-link').on('click', function () {
    loadDelayedImages($('#locations-overlay'));
    activateOverlay($('#locations-overlay'));
  });

  $('.contact-form-overlay-link').on('click', function () {
    activateOverlay($('#contact-form-overlay'));
  });

  $('.page-nav-overlay-link').on('click', function () {
    activateOverlay($('#page-nav-overlay'));
  });

  $('.blog-tags-overlay-link').on('click', function () {
    activateOverlay($('#blog-tags-overlay'));
  });

  $('.blog-archive-overlay-link').on('click', function () {
    activateOverlay($('#blog-archive-overlay'));
  });

  $('.social-accounts-overlay-link').on('click', function () {
    activateOverlay($('#social-accounts-overlay'));
  });

  if ($videoOverlay) {
    var cta = document.querySelector('.after-play-wrapper');

    $('.video-overlay-link').on('click', function () {
      activateOverlay($('#video-overlay'));
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });

    $('.video-overlay-replay-link').on('click', function () {
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });
  }

  // "View All" tab's groups
  $('.page-nav .page-group-link').on('click', function () {
    $(this).parent().toggleClass('active');
  });
});

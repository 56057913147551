var EI = window.EI || {}


// Referrer class
//
// Encapsulates document referrer information
//
EI.Referrer = function() {
  var _referrer = document.referrer;

  this.hostname = function() {
    return _referrer.replace(/(https?:\/\/|www\.|\/.*$)/g,'');
  }
  this.toString = function() {
    if (_referrer == "") {
      return "-";
    } else {
      return _referrer;
    }
  }
}

// ReferrerMadness core javascript
//
// Requires: EI.Referrer
//
// This namespace manages cookie storage and form submission of referrer info.
// A hidden input holding the referrer value is appended to each form element.
//
EI.ReferrerMadness = function() {
  var _cookie_name   = '_ei_referrer';
  var _referrer      = new EI.Referrer;
  var _cookie        = getCookie(_cookie_name);
  var _last_referrer = _cookie || '';
  var _hostname      = document.location.hostname.replace('www.','');

  function isNewReferrer() {
    if (_referrer.hostname() == '' || _referrer.hostname() == _hostname) {
      return false;
    }
    return (_last_referrer != _referrer);
  }

  function storeNewReferrer() {
    setCookie(_cookie_name, _referrer, 0);
    _cookie = getCookie(_cookie_name);
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) {
        return unescape(c.substring(nameEQ.length,c.length));
      }
    }
    return null;
  }

  function setCookie(name, value, expdays) {
    if (expdays) {
      var date = new Date();
      date.setTime(date.getTime()+(expdays*24*60*60*1000));
      var expires = "; expires="+date.toGMTString();
    } else {
      var expires = "";
    }
    document.cookie = name+"="+escape(value)+expires+"; path=/";
  }


  function insertReferrerFieldToForms() {
    var _forms = document.getElementsByTagName('form');
    for (var i=0; i<_forms.length; i++) {
      if (_forms[i].action.indexOf('eiiforms.com') == -1) { continue; }
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'referrer_url');
      input.setAttribute('value', _cookie);

      var input2 = document.createElement('input');
      input2.setAttribute('type', 'hidden');
      input2.setAttribute('name', 'visitor_referrer');
      input2.setAttribute('value', window.location.href);

      _forms[i].appendChild(input);
      _forms[i].appendChild(input2);
    }
  }

  function initialize() {
    if (isNewReferrer()) storeNewReferrer();
    insertReferrerFieldToForms();
  }

  function addLoadEvent(func) {
    var oldonload = window.onload;
    if (typeof window.onload != 'function') {
      window.onload = func;
    } else {
      window.onload = function() {
        if (oldonload) {
          oldonload();
        }
        func();
      }
    }
  }

  addLoadEvent(initialize);

}();

/**
 * Enables Preview Mode when someone clicks "Preview" from the sidebar.
 *
 * When the current url has the preview param, the param gets appended to all
 * anchors with a relative href
 *
 * Include this file in your gulpfile to enable.
 */
function LucidPreviewMode() {
  this.relativeUrlRegex =
    new RegExp(['^https?:\\/\\/', window.location.hostname].join(''));

  this.inPreviewMode = function () {
    return window.location.search === '?lucid_page_version=preview';
  };

  this.hasRelativeHref = function (anchor) {
    return this.relativeUrlRegex.test(anchor.href);
  };

  this.hasPreviewParam = function (anchor) {
    return anchor.href.indexOf('lucid_page_version=preview') > 0;
  };

  this.enable = function () {
    var page = this;

    if (!page.inPreviewMode()) return;

    $('a').each(function () {
      var anchor = this;
      if (!page.hasRelativeHref(anchor) || page.hasPreviewParam(anchor)) return;

      anchor.href = anchor.href + '?lucid_page_version=preview';
    });
  };
}

/**
 * Enables Color Style Mode when someone visits a page with the theme_style
 * param set.
 *
 * When the current url has the theme_style param, the param gets appended to
 * all anchors with a relative href.  This allows the user to continue
 * previewing the theme's color style.
 *
 * Include this file in your gulpfile to enable.
 */
function LucidColorStyleMode() {
  this.getColorStyle = function () {
    matches = window.location.search.match(/\?theme_style=([\w-]+)/);
    if (!matches) return;

    return matches[1];
  };

  this.relativeUrlRegex =
    new RegExp(['^https?:\\/\\/', window.location.hostname].join(''));
  this.colorStyle = this.getColorStyle();

  this.hasRelativeHref = function (anchor) {
    return this.relativeUrlRegex.test(anchor.href);
  };

  this.hasColorParam = function (anchor) {
    return /\?theme_style=\w+/.test(anchor.href);
  };

  this.enable = function () {
    var page = this;

    if (!page.colorStyle) return;

    $('a').each(function () {
      var anchor = this;
      if (!page.hasRelativeHref(anchor) || page.hasColorParam(anchor)) return;

      anchor.href = anchor.href + '?theme_style=' + page.colorStyle;
    });
  };
}

jQuery(function($) {
  var previewMode    = new LucidPreviewMode();
  var colorStyleMode = new LucidColorStyleMode();

  previewMode.enable();
  colorStyleMode.enable();
});
